import React from 'react';
import { Helmet } from "react-helmet";

const Cookies = (props) => {
  return (
    <Helmet>
      {/* Produção OptanonConsentNoticeStart */}
      <script type="text/javascript" src="https://cdn.cookielaw.org/consent/94a861fe-41c1-43b7-aed6-193d3951847b/OtAutoBlock.js" ></script>

      <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" charset="UTF-8" data-domain-script="94a861fe-41c1-43b7-aed6-193d3951847b" ></script>
      {/*OptanonConsentNoticeEnd */}
    </Helmet>
  )
};
export default Cookies;